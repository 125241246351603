$(document).ready(function() {
    createTabNav('news-teaser',true,true);

    $('.afs-news-teaser .owl-carousel').owlCarousel({
        loop:false,
        stagePadding: 0,
        autoWidth:false,
        margin: 0,
        nav:false,
        responsive:{
            0:{
                items:1
            },
            780:{
                items:2
            },
            1170:{
                items:3
            }
        }
    });
});